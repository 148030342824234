@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Roboto&display=swap');

body {
    background: rgb(249 250 251);
    font-family: 'Roboto', sans-serif;
}

.poppins-font {
    font-family: 'Poppins', sans-serif;
}

#soan-logo {
    width: 8em
}

nav .nav-link {
    position: relative;
}

nav .nav-link .select-nav-link {
    margin-top: 0.3em;
    position: absolute;
    width: 100%;
    height: 5px;
}

nav .nav-link.active {
    border-bottom: 3px solid #000000;
}

#loginForm{
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(2, 19, 14, 0.05);
    padding: 3em;
    border-radius: 0.5em;
}

#loginForm input:focus {
    outline: none;
    border-color: #6500E5;
}